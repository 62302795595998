<template>
  <div class="alertasBox" @click="ativarEvento(alerta.idAlerta)">
  <!-- <div class="alertasBox" @click="scrollTo()"> -->
    <div class="iconeBox">
      <img
        :src="iconeAlerta"
        class="iconeAtivo"
        :class="{ desativado: !alerta.valor }"
      />
    </div>
    <div
      class="descTes sb-2"
      :class="{ 'desativado borda-desativada': !alerta.valor }"
    >
      <span :id="tipoAlerta + '-tooltip-' + index">
        {{ alerta.desc }}
      </span>
    </div>
    <b-tooltip
    v-if="exibirTootipsConsultas"
      :target="tipoAlerta + '-tooltip-' + index"
      triggers="hover"
      placement="bottom"
      variant="dark"
      class="tooltipTexts"
      boundary="window"
    >
      {{ alerta.tooltipText }}
    </b-tooltip>
    <div class="checkBox sb-1" :class="{ 'borda-desativada': !alerta.valor }">
      <Check :valor="alerta.valor" />
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapActions, mapWritableState } from "pinia";

export default {
  name: "B9ItemAlerta",

  components: { Check },

  data() {
    return {
      arrowOpen: "/img/superbox/arrow-bottom.png",
    };
  },

  props: {
    alerta: Object,
    index: Number,
    iconeAlerta: String,
    tipoAlerta: String,
    exibirTootipsConsultas: Boolean,
  },

  methods: {
    scrollTo() {
      if (this.alerta.valor) {
        const bloco = this.alerta.blocoRef;
        this.$emit("scrollToBlock", bloco);
      }
    },

    ativarEvento(chave) {
      this.ativarEvento(chave);
    },

    ...mapActions(storeAlertasSuperBox, ["ativarEvento"]),

  },

  computed: {},

  created() {},
};
</script>

<style lang="scss" scoped>
.alertasBox {
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.iconeBox {
  height: 25px;
  width: 25px;
  display: flex;
}

.descTes {
  border: solid 1px #0070c0;
  border-radius: 10px;
  margin: 0px 10px;
  padding: 1px 10px;
  font-size: 12px;
  height: 40px;
  vertical-align: middle;
  cursor: pointer;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.checkBox {
  border: solid 1px #0070c0;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desativado {
  opacity: 0.5;
}
.borda-desativada {
  border: solid 1px #aaaaaa;
  opacity: 0.5;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
