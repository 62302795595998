<template>
  <div class="alertasBox">
    <span class="alertaTitle sb-3" :id="tituloId" :class="corSelecionada">
      {{ alertasTitulo }}
    </span>
    <div class="alertasBody">
      <div class="alertasBodyBox iconBox sb-1">
        <img
          class="icon"
          :src="iconAlerta"
          :class="{ semAlerta: qtdAlertas == 0 }"
        />
      </div>
      <div class="alertasBodyBox desc sb-1">
        <span class="check"> Check </span>
        <span class="qtde"> Qtde </span>
      </div>
      <div class="alertasBodyBox sb-1">
        <span class="respBox sb-1">
          <Check :valor="qtdAlertas > 0" />
        </span>
        <span class="respBox sb-1">
          {{ qtdAlertas }}
        </span>
      </div>
    </div>
    <div v-for="(tt, i) in tooltips" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapWritableState } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B9TipoAlerta",

  components: {
    Check,
  },

  data() {
    return {};
  },

  props: {
    iconAlerta: String,
    alertasTitulo: String,
    tipoSelecionado: String,
    exibirTootipsConsultas: Boolean,
  },

  methods: {},

  created() {},

  computed: {
    ...mapWritableState(storeAlertasSuperBox, [
      "alertas_altos",
      "alertas_baixos",
      "alertas_positivos",
    ]),

    qtdAlertas() {
      if (this.alertasTitulo == "Alertas de Alta Prioridade") {
        return this.alertas_altos.filter((alerta) => alerta.valor).length;
      }
      if (this.alertasTitulo == "Alertas de Baixa Prioridade") {
        return this.alertas_baixos.filter((alerta) => alerta.valor).length;
      }
      if (this.alertasTitulo == "Alertas Positivos") {
        return this.alertas_positivos.filter((alerta) => alerta.valor).length;
      } else {
        return 0;
      }
    },

    tituloId() {
      if (this.alertasTitulo == "Alertas de Alta Prioridade") {
        return "BlocoPFAlertas-titulo-alta";
      }
      if (this.alertasTitulo == "Alertas de Baixa Prioridade") {
        return "BlocoPFAlertas-titulo-baixa";
      }
      if (this.alertasTitulo == "Alertas Positivos") {
        return "BlocoPFAlertas-titulo-positivo";
      } else {
        return "";
      }
    },

    corSelecionada() {
      if (this.alertasTitulo == "Alertas de Alta Prioridade") {
        if (this.tipoSelecionado == "vermelho") {
          return this.tipoSelecionado;
        }
      }
      if (this.alertasTitulo == "Alertas de Baixa Prioridade") {
        if (this.tipoSelecionado == "amarelo") {
          return this.tipoSelecionado;
        }
      }
      if (this.alertasTitulo == "Alertas Positivos") {
        if (this.tipoSelecionado == "verde") {
          return this.tipoSelecionado;
        }
      }
      return "";
    },

    tooltips() {
      const listaGeral = listaTooltips.blocoAlertasGerais;

      return listaGeral.filter((tt) => tt.target == this.tituloId);
    },
  },
};
</script>

<style lang="scss" scoped>
.alertasBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
}

.alertaTitle {
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 40px;
  font-weight: 500;
  cursor: default;
  transition: 0.5s;

  &.vermelho {
    background-color: #b72026;
    color: white;
  }

  &.amarelo {
    background-color: #e1e610;
    color: black;
  }

  &.verde {
    background-color: #26cbc6;
    color: white;
  }

  @include flex-center(row);
}

.alertasBody {
  @include flex-center(row);
  flex: 1;
}

.alertasBodyBox {
  height: 90px;
  border-radius: 5px;

  @include flex-center(column);
  justify-content: space-evenly !important;

  &.iconBox {
    background-color: #d9d9d9;
    margin: 5px 5px 0 0;
  }
  &.desc {
    background-color: #d9d9d9;
    margin: 5px 5px 0 0;
  }
}
.respBox {
  border: solid 1px #0070c0;
  border-radius: 5px;
  display: flex;
  flex: 1;
  margin: 5px 5px 0px 5px;
  justify-content: center;
  align-items: center;
  height: 33px;
}
.icon {
  width: 55px;

  &.semAlerta {
    opacity: 0.6;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
