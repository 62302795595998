<template>
  <div class="alertasBox" v-if="abreBloco">
    <img :src="contorno" class="img-contorno" />
    <transition name="fade">
      <img :src="imgTipoSelecionado" class="img-tipo-contorno" />
    </transition>

    <span
      class="area verm"
      @mouseover="handleHover('vermelho')"
      @mouseleave="handleHoverLeave()"
    ></span>
    <span
      class="area amar"
      @mouseover="handleHover('amarelo')"
      @mouseleave="handleHoverLeave()"
    ></span>
    <span
      class="area verd"
      @mouseover="handleHover('verde')"
      @mouseleave="handleHoverLeave()"
    ></span>
    <div class="alertasBody">
      <div
        class="alertasBodyBox azul-claro sb-1 sb-right"
        id="BlocoPFAlertas-titulo-total"
      >
        Possui
      </div>
      <div class="respBox possui sb-1 sb-right">
        <span class="box-checks">
          <img
            v-if="valorCheck && valorCheck != null"
            class="check"
            :src="iconCheckGreen"
          />
          <img
            v-if="!valorCheck || valorCheck == null"
            class="check"
            :src="iconCheckGray"
          />
          <img
            v-if="!valorCheck && valorCheck != null"
            class="check"
            :src="iconXRed"
          />
          <img
            v-if="valorCheck || valorCheck == null"
            class="check"
            :src="iconXGray"
          />
        </span>
      </div>
      <div class="alertasBodyBox sb-2">
        <span class="alertasBodyBox desc azul-claro sb-2"> Quantidade </span>
        <span class="respBox sb-2 sb-top">
          {{ qtdAlertas }}
        </span>
      </div>
    </div>
    <div class="prop">
      <span class="circulo" :class="tipoSelecionado">
        {{ porcentagemAlertas }}
      </span>
      <img :src="getImagem(porcentagemAlertas)" class="img-prop" />
    </div>
    <div class="desc-box">
      <span class="desc-title"> Interpretação </span>

      <span class="desc-text" v-html="textoInterpretacao"></span>
    </div>
    <div v-for="(tt, i) in tooltips" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapWritableState } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B9TipoAlertasGerais",

  components: {},

  data() {
    return {
      contorno: "/img/superbox/alertas-gerais/Contorno.png",
      iconCheckGreen: "/img/superbox/check-green.png",
      iconCheckGray: "/img/superbox/check-gray.png",
      iconXGray: "/img/superbox/x-gray.png",
      iconXRed: "/img/superbox/x-red.png",

      imgTipoSelecionado: "/img/superbox/alertas-gerais/tipos-alertas-azul.png",
      tipoSelecionado: "azul",
      valorCheck: false,
    };
  },

  props: {
    iconAlerta: String,
    alertasTitulo: String,
    abreBloco: Boolean,
    exibirTootipsConsultas: Boolean,
  },

  methods: {
    handleHover(tipo) {
      this.imgTipoSelecionado = `/img/superbox/alertas-gerais/tipos-alertas-${tipo}.png`;
      this.tipoSelecionado = tipo;
      this.$emit("recebeTipoAlerta", this.tipoSelecionado);
    },

    handleHoverLeave() {
      this.imgTipoSelecionado =
        "/img/superbox/alertas-gerais/tipos-alertas-azul.png";
      this.tipoSelecionado = "azul";
      this.$emit("recebeTipoAlerta", this.tipoSelecionado);
    },

    getImagem(valor) {
      const numericValue = parseInt(valor.replace("%", ""), 10);

      if (numericValue === 0 || valor == null) {
        return "/img/superbox/alertas-gerais/Círculo-0.png";
      }

      const imageIndex = Math.min(Math.ceil(numericValue / (100 / 12)), 12);
      return `/img/superbox/alertas-gerais/Círculo-${imageIndex}.png`;
    },
  },

  watch: {
    qtdAlertas(newValue) {
      if (newValue > 0) {
        this.valorCheck = true;
      }
    },
  },

  created() {},

  mounted() {
    if (this.qtdAlertas > 0) {
      this.valorCheck = true;
    }
  },

  computed: {
    ...mapWritableState(storeAlertasSuperBox, [
      "alertas_altos",
      "alertas_baixos",
      "alertas_positivos",
    ]),

    porcentagemAlertasGeral() {
      var totalDeAlertasAtivos = 0;

      totalDeAlertasAtivos =
        (this.qtdAlertas / this.totalAlertasExistentes) * 100;

      return Math.round(totalDeAlertasAtivos) + "%";
    },

    porcentagemAlertas() {
      if (this.tipoSelecionado == "azul") {
        var totalDeAlertasAtivos = 0;

        totalDeAlertasAtivos =
          (this.qtdAlertas / this.totalAlertasExistentes) * 100;

        totalDeAlertasAtivos = Math.round(totalDeAlertasAtivos) + "%";
      }
      if (this.tipoSelecionado == "vermelho") {
        var totalDeAlertasAtivos = 0;

        var alertasAtivos = this.alertas_altos.filter(
          (alerta) => alerta.valor
        ).length;
        totalDeAlertasAtivos =
          (alertasAtivos / this.alertas_altos.length) * 100;

        totalDeAlertasAtivos = Math.round(totalDeAlertasAtivos) + "%";
      }
      if (this.tipoSelecionado == "amarelo") {
        var totalDeAlertasAtivos = 0;
        var alertasAtivos = this.alertas_baixos.filter(
          (alerta) => alerta.valor
        ).length;
        totalDeAlertasAtivos =
          (alertasAtivos / this.alertas_baixos.length) * 100;

        totalDeAlertasAtivos = Math.round(totalDeAlertasAtivos) + "%";
      }
      if (this.tipoSelecionado == "verde") {
        var totalDeAlertasAtivos = 0;

        var alertasAtivos = this.alertas_positivos.filter(
          (alerta) => alerta.valor
        ).length;
        totalDeAlertasAtivos =
          (alertasAtivos / this.alertas_positivos.length) * 100;

        totalDeAlertasAtivos = Math.round(totalDeAlertasAtivos) + "%";
      }

      return totalDeAlertasAtivos;
    },

    totalAlertasExistentes() {
      return (
        this.alertas_altos.length +
        this.alertas_baixos.length +
        this.alertas_positivos.length
      );
    },

    qtdAlertas() {
      if (this.alertasTitulo == "Alertas de Alta Prioridade") {
        return this.alertas_altos.filter((alerta) => alerta.valor).length;
      }
      if (this.alertasTitulo == "Alertas de Baixa Prioridade") {
        return this.alertas_baixos.filter((alerta) => alerta.valor).length;
      }
      if (this.alertasTitulo == "Alertas Positivos") {
        return this.alertas_positivos.filter((alerta) => alerta.valor).length;
      }
      if (this.alertasTitulo == "Total de Alertas Encontados") {
        const totalAlertas = [
          ...this.alertas_altos,
          ...this.alertas_baixos,
          ...this.alertas_positivos,
        ];
        return totalAlertas.filter((alerta) => alerta.valor).length;
      } else {
        return 0;
      }
    },

    textoInterpretacao() {
      var texto;
      var textoInicio =
        "Foram realizados um total de <strong style='color: #a69be0'>" +
        this.totalAlertasExistentes +
        " </strong>varreduras em busca de circunstâncias relevantes para apontamentos. <br/><br/>";

      if (this.qtdAlertas == 0 || this.qtdAlertas == null) {
        texto =
          textoInicio +
          "Não foram detectados atributos atípicos associados ao CPF consultado, resultando em '0' alertas nesta consulta.";
      } else {
        if (this.tipoSelecionado == "azul") {
          if (this.qtdAlertas > 1) {
            texto =
              textoInicio +
              "Para todos os pontos avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertasGeral +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              this.qtdAlertas +
              " </strong>alertas ativos.";
          } else {
            texto =
              textoInicio +
              "Para todos os pontos avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertasGeral +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              this.qtdAlertas +
              " </strong>alerta ativo.";
          }
        } else if (this.tipoSelecionado == "vermelho") {
          var alertasAtivos = this.alertas_altos.filter(
            (alerta) => alerta.valor
          ).length;
          if (alertasAtivos != 1) {
            texto =
              "Dentre os <strong style='color: #a69be0'>" +
              this.alertas_altos.length +
              "</strong> alertas de alta prioridade avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertas +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              alertasAtivos +
              " </strong>alertas ativos.";
          } else {
            texto =
              "Dentre os <strong style='color: #a69be0'>" +
              this.alertas_altos.length +
              "</strong> alertas de alta prioridade avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertas +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              alertasAtivos +
              " </strong>alerta ativo.";
          }
        } else if (this.tipoSelecionado == "amarelo") {
          var alertasAtivos = this.alertas_baixos.filter(
            (alerta) => alerta.valor
          ).length;
          if (alertasAtivos != 1) {
            texto =
              "Dentre os <strong style='color: #a69be0'>" +
              this.alertas_baixos.length +
              "</strong> alertas de baixa prioridade avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertas +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              alertasAtivos +
              " </strong>alertas ativos.";
          } else {
            texto =
              "Dentre os <strong style='color: #a69be0'>" +
              this.alertas_baixos.length +
              "</strong> alertas de baixa prioridade avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertas +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              alertasAtivos +
              " </strong>alerta ativo.";
          }
        } else if (this.tipoSelecionado == "verde") {
          var alertasAtivos = this.alertas_positivos.filter(
            (alerta) => alerta.valor
          ).length;
          if (alertasAtivos != 1) {
            texto =
              "Dentre os <strong style='color: #a69be0'>" +
              this.alertas_positivos.length +
              "</strong> alertas positivos avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertas +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              alertasAtivos +
              " </strong>alertas ativos.";
          } else {
            texto =
              "Dentre os <strong style='color: #a69be0'>" +
              this.alertas_positivos.length +
              "</strong> alertas positivos avaliados, <strong style='color: #a69be0'>" +
              this.porcentagemAlertas +
              " </strong>retornaram apontamentos relevantes, totalizando <strong style='color: #a69be0'>" +
              alertasAtivos +
              " </strong>alerta ativo.";
          }
        }
      }
      return texto;
    },

    tooltips() {
      const listaGeral = listaTooltips.blocoAlertasGerais;

      return listaGeral.filter(
        (tt) => tt.target == "BlocoPFAlertas-titulo-total"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.img-contorno {
  width: 600px;
  position: absolute;
  left: 0;
  top: 115px;
}
.img-tipo-contorno {
  height: 257px;
  position: absolute;
  left: 486px;
}

.area {
  position: absolute;
  z-index: 10;
  cursor: default;
  transition: 1s;

  &.verm {
    left: 573px;
    width: 42px;
    height: 90px;
    top: 140px;
  }
  &.amar {
    left: 564px;
    width: 50px;
    height: 94px;
    top: 235px;
  }
  &.verd {
    left: 486px;
    width: 75px;
    height: 26px;
    top: 331px;
  }
}

.alertasBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 230px;
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
  padding: 0 3%;
  cursor: default;
}

.box-checks {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.check {
  height: 30px;
  width: 30px;
}

.alertasBody {
  display: flex;
  z-index: 2;
}

.alertasBodyBox {
  height: 90px;
  border-radius: 5px;

  @include flex-center(column);
  justify-content: space-evenly !important;

  &.desc {
    height: 40px;
  }
}
.respBox {
  border: solid 1px #0070c0;
  border-radius: 5px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 40px;

  &.possui {
    height: 90px;
    flex-direction: column;
  }
}

.azul-claro {
  background-color: #d9e1f2;
  color: #363636;
}

.prop {
  position: relative;
  left: 8px;
  cursor: default;
}

.circulo {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  z-index: 0;
  cursor: default;
  transition: 0.5s;
  display: flex;
  position: absolute;
  left: 45px;
  top: 45px;
  color: white;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  align-items: center;
  justify-content: center;

  &.azul {
    background-color: #0e31af;
  }

  &.vermelho {
    background-color: #b72026;
  }

  &.amarelo {
    background-color: #e1e610;
    color: black;
  }

  &.verde {
    background-color: #26cbc6;
  }
}

.img-prop {
  width: 195px;
}

.desc-box {
  display: flex;
  flex-direction: column;
  width: 290px;
  position: relative;
  left: 60px;

  .desc-title {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a69be0;
    border-radius: 10px 10px 0 0;
    color: white;
  }

  .desc-text {
    width: 100%;
    background-color: white;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    line-height: 18px;
    height: 150px;
    align-content: center;
  }
}
.texto-destaque {
  color: #a69be0;
  font-weight: 600;
}
</style>

<style>
.alertasBox .fade-enter-active,
.alertasBox .fade-leave-active {
  transition: opacity 1s ease;
}

.alertasBox .fade-enter,
.alertasBox .fade-leave-to {
  opacity: 0;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
