import { render, staticRenderFns } from "./B9ItemAlerta.vue?vue&type=template&id=1b14299c&scoped=true&"
import script from "./B9ItemAlerta.vue?vue&type=script&lang=js&"
export * from "./B9ItemAlerta.vue?vue&type=script&lang=js&"
import style0 from "./B9ItemAlerta.vue?vue&type=style&index=0&id=1b14299c&prod&lang=scss&scoped=true&"
import style1 from "@/styles/components/superbox_customizado.scss?vue&type=style&index=1&id=1b14299c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b14299c",
  null
  
)

export default component.exports